import * as React from 'react';
import * as styles from './top-content-wrapper.module.scss';
import { ContactData } from '../contact-data/contact-data';
import { BusinessHours } from '../business-hours/business-hours';

export const TopContentWrapper = () => (
  <div className={`${styles.wrapper} row flex-column-reverse flex-md-row`}>
    <ContactData className="col-12 col-md-6" />
    <BusinessHours className="col-12 col-md-6" />
  </div>
);
