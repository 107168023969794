import * as React from 'react';
import { PageProps } from 'gatsby';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../components/page-headline/page-headline';
import { TopContentWrapper } from '../ui/pages/contacts/top-content-wrapper/top-content-wrapper';
import { GoogleMap } from '../ui/pages/contacts/google-map/google-map';
import { ContactForm } from '../ui/pages/contacts/contact-form/contact-form';

const ContactsPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'contacts.seo_title' })}
        description={intl.formatMessage({ id: 'contacts.seo_description' })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="contacts.headline" />
        </PageHeadline>
        <TopContentWrapper />
        <GoogleMap />
        <ContactForm />
      </div>
    </MainTemplate>
  );
};

export default ContactsPage;
