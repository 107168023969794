import * as React from 'react';
import * as styles from './contact-data.module.scss';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { ContactData as IContactData } from '../../../../interfaces/ContactData';
import { ButtonBase, Chip } from '@material-ui/core';
import { Icon, IconType } from '../../../../components/icon/icon';
import { useSiteMetadata } from '../../../../hooks/useSiteMetadata';

interface IProps {
  className: string;
}
export const ContactData = ({ className = '' }: IProps) => {
  const intl = useIntl();
  const { contacts, siteMainLang } = useSiteMetadata();

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <h2 className={styles.headline}>
        <FormattedMessage id="contacts.contact_data.headline" />
      </h2>
      <div>
        <h3>
          <FormattedMessage id="general.phone" />
        </h3>
        {contacts?.tel?.map((contact: IContactData) => (
          <ButtonBase
            key={contact.phone}
            className={`${styles.contact} d-flex justify-content-between`}
            href={`tel:${contact.phone}`}
          >
            <div className={styles.nameTitleWrapper}>
              <p className={styles.name}>
                {intl.locale === siteMainLang ? contact.nameBG : contact.nameEN}
              </p>
              <Chip
                variant="outlined"
                color="secondary"
                size="small"
                label={intl.formatMessage({
                  id: `general.staff_titles.${contact.titleTranslationKey}`,
                })}
              />
            </div>
            <div className="d-flex align-items-center flex-shrink-0">
              <p className={styles.phone}>{`${contact.phone.slice(
                0,
                4
              )} ${contact.phone.slice(4)}`}</p>
              <Icon name={IconType.PHONE} className={styles.icon} />
            </div>
          </ButtonBase>
        ))}
      </div>
      <h3 className="d-flex justify-content-between align-items-center">
        <FormattedMessage id="general.email" />
        <a href={`mailto:${contacts.email}`}>
          <FormattedMessage id="general.company.email" />
        </a>
      </h3>
    </div>
  );
};
