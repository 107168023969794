import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import * as styles from './contact-form.module.scss';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-react-intl';
import { Button } from '../../../../components/button/button';
import { ROUTES } from '../../../../const/routes';
import { Routes } from '../../../../enum/Routes';

export const ContactForm = () => {
  const intl = useIntl();
  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const encode = (data: Record<string, string>) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.headline}>
        <FormattedMessage id="contacts.contact_form.headline" />
      </h2>
      <p
        className={styles.subHeadline}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'contacts.contact_form.sub_headline',
          }),
        }}
      />
      <form
        autoComplete="off"
        name="contact"
        method="POST"
        action={ROUTES[Routes.THANK_YOU]()}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="contact-form" value="contact" />
        <div className="row">
          <div className="col-12 col-md-4">
            <div className={styles.field}>
              <label htmlFor="name">
                <FormattedMessage id="contacts.contact_form.form.fields.name" />
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                onChange={handleChange}
                value={formState.name}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className={styles.field}>
              <label htmlFor="phone">
                <FormattedMessage id="contacts.contact_form.form.fields.phone" />
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                onChange={handleChange}
                value={formState.phone}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className={styles.field}>
              <label htmlFor="email">
                <FormattedMessage id="contacts.contact_form.form.fields.email" />
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                onChange={handleChange}
                value={formState.email}
              />
            </div>
          </div>
        </div>
        <div className={styles.field}>
          <label htmlFor="message">
            <FormattedMessage id="contacts.contact_form.form.fields.message" />
          </label>
          <textarea
            rows={4}
            id="message"
            name="message"
            required
            onChange={handleChange}
            value={formState.message}
          />
        </div>
        <div>
          <Button type="submit" className={styles.submitButton}>
            <FormattedMessage id="contacts.contact_form.form.submit" />
          </Button>
        </div>
      </form>
    </div>
  );
};
