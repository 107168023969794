import * as React from 'react';
import * as styles from './google-map.module.scss';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

export const GoogleMap = () => {
  const intl = useIntl();

  return (
    <div className={styles.wrapper}>
      <h2>
        <FormattedMessage id="general.address" />
      </h2>

      <div className="row">
        <div className={`${styles.column} col-12 col-md-6`}>
          <p>
            <FormattedMessage id="general.company.address" />
          </p>
          <div className={styles.mapWrapper}>
            <iframe
              className={styles.map}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11946.96817265099!2d24.9061161!3d41.531528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf0b959749050a730!2z0JPRgNCw0L3QtCDQntCe0JQgLSDQn9GA0L7QuNC30LLQvtC00YHRgtCy0L4g0L3QsCDQutCw0YjQvtC90Lgg0Lgg0L7Qv9Cw0LrQvtCy0LrQuA!5e0!3m2!1sde!2sde!4v1648126066833!5m2!1sde!2sde"
              title={intl.formatMessage({ id: 'contacts.google_map_title' })}
              allowFullScreen={true}
              loading="lazy"
            />
          </div>
        </div>
        <div className={`${styles.column} col-12 col-md-6`}>
          <p>
            <FormattedMessage id="general.company.address_line_two" />
          </p>
          <div className={styles.mapWrapper}>
            <iframe
              className={styles.map}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2956.2253720463773!2d24.37109921546178!3d42.18824847919882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x41c14effbcd0cec!2zNDLCsDExJzE3LjciTiAyNMKwMjInMjMuOCJF!5e0!3m2!1sbg!2sde!4v1637605834236!5m2!1sbg!2sde"
              title={intl.formatMessage({ id: 'contacts.google_map_title' })}
              allowFullScreen={true}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
