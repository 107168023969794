import * as React from 'react';
import * as styles from './business-hours.module.scss';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { useSiteMetadata } from '../../../../hooks/useSiteMetadata';
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import { Icon, IconType } from '../../../../components/icon/icon';
import { withStyles } from '@material-ui/styles';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    margin: '5px 0',
  },
}))(Tooltip);

interface BusinessDayData {
  dayTranslationKey: string;
  hours: string;
}

interface IProps {
  className: string;
}
export const BusinessHours = ({ className = '' }: IProps) => {
  const { company } = useSiteMetadata();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  // Since sunday is 0, we will transform the day in order to map the days from monday to sunday.
  // Maybe it is not the best way to do it, but in this case it is fine.
  // So mon = 1, tue = 2... sun = 7
  const currentDayOfTheWeek = !new Date().getDay() ? 7 : new Date().getDay();
  const businessHoursData = company.businessHours.detailed;

  return (
    <div className={className}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.headlineWrapper}`}
      >
        <h2 className={styles.headline}>
          <FormattedMessage id="contacts.business_hours.headline" />
        </h2>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <LightTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={intl.formatMessage({
              id: 'contacts.business_hours.more_info.tooltip',
            })}
            placement="bottom-end"
          >
            <IconButton
              size="small"
              className={styles.infoIcon}
              color="secondary"
              onClick={handleTooltipOpen}
              aria-label={intl.formatMessage({
                id: 'contacts.business_hours.more_info.button_alt',
              })}
            >
              <Icon name={IconType.INFO} />
            </IconButton>
          </LightTooltip>
        </ClickAwayListener>
      </div>
      <ul className={styles.businessHoursList}>
        {businessHoursData.map(
          ({ dayTranslationKey, hours }: BusinessDayData, index: number) => (
            <li
              className={`d-flex justify-content-between align-items-center ${
                currentDayOfTheWeek === index + 1 ? styles.highlightText : ''
              }`}
              key={dayTranslationKey}
            >
              <div>
                <FormattedMessage
                  id={`contacts.business_hours.days.${dayTranslationKey}`}
                />
              </div>
              <div>
                {!!hours ? (
                  hours
                ) : (
                  <FormattedMessage id="contacts.business_hours.closed" />
                )}
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};
